<template>
  <v-dialog
    v-model="dialog"
    scrollable
    :persistent="loading"
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on: d }">
      <v-tooltip left>
        <span>Points</span>
        <template v-slot:activator="{ on: tt }">
          <v-btn
            fab
            color="color3 color3Text--text"
            small
            v-on="{ ...tt, ...d }"
          >
            <v-icon small>fas fa-list-ol</v-icon>
          </v-btn>
        </template>
      </v-tooltip>
    </template>
    <v-card>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>Player Points</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color1 color1Text--text"
          fab small @click.stop="dialog = false"
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-window v-model="window" touchless>
          <v-window-item :key="0">
            <div class="title">Status: {{status.title}}</div>
            <div>{{status.text}}</div>
            <v-btn
              color="color3 color3Text--text"
              v-if="status.button"
              @click.stop="window = 1"
            >{{status.button}}</v-btn>
          </v-window-item>
          <v-window-item :key="1">
            <div class="title text-center">Are you sure?</div>
            <div v-if="status.warn">{{status.warn}}</div>
            <div class="text-center">
              <v-btn
                color="success"
                class="mr-3"
                :loading="loading"
                @click.stop="pullPoints"
              >Yes</v-btn>
              <v-btn
                color="error"
                @click.stop="window = 0"
                :disabled="loading"
              >No</v-btn>
            </div>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['division'],
  data () {
    return {
      dialog: false,
      window: 0,
      loading: false
    }
  },
  computed: {
    noLoad () {
      return this.division.props.includes('noPoints')
    },
    a () {
      return {
        locked: this.division.activeTeams.filter(f => !f.pointsLocked),
        issue: this.division.pointsIssue
      }
    },
    status () {
      if (this.noLoad) {
        return {
          title: 'Autoload disabled',
          text: 'An administrator has disabled the automatic loading of points for this division. This is usually done in bigger events to speed up load time.',
          button: 'Load Points'
        }
      }
      if (this.division.pointsIssue) {
        return {
          title: 'Points Issue',
          text: 'Points are locked when you seed the division. When a player is added or swapped after seeding, it does not automatically update the team points. To fix the issue force a point update, then re-seed to lock the points',
          button: 'Force an Update',
          warn: 'This will overwrite the points that were locked when you last seeded the event.'
        }
      }
      if (this.division.pointsLocked) {
        return {
          title: 'Locked',
          text: 'Points are locked when you seed the division.',
          button: 'Force an Update',
          warn: 'This will overwrite the points that were locked when you last seeded the event.'
        }
      }
      if (this.division.missingPoints) {
        return !this.division.hydrated ? {
          title: 'Awaiting hydration',
          text: 'Points are being loaded in the background. Please give it a few more seconds to complete.'
        } : {
          title: 'Missing Points',
          text: 'Something went wrong. First try refreshing the page. If you still get this message, let us know.',
          button: 'Force an Update',
          warn: 'This will overwrite the points that were locked when you last seeded the event.'
        }
      }
      return {
        title: 'Huh',
        text: '???'
      }
    }
  },
  methods: {
    pullPoints () {
      this.loading = true
      this.$VBL.division.pullPoints(this.division.id)
        .then(r => { this.dialog = false })
        .catch(e => console.log(e.response))
        .finally(() => { this.loading = false })
    }
  },
  watch: {
    dialog: function (val) {
      this.window = 0
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
